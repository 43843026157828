@import './color';

.customize-dropdown-antd {
  position: fixed;
  top: 60px !important;
  z-index: 2000;

  ul {
    background-color: transparent !important;

    li {
      border-bottom: 1px solid $border_bottom_header_dropdown;
      border-radius: 0 !important;
      color: white !important;

      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 500 !important;
      font-size: 18px !important;
      line-height: 22px !important;
      letter-spacing: -0.014em !important;

      transition: none !important;
      &:hover {
        font-weight: 700 !important;
      }
    }

    li.customize-dropdown-antd-menu-item-selected {
      background-color: initial !important;
      color: $active_menu !important;
    }

    li:last-child {
      border: none;
    }

    .active {
      color: $active_menu !important;
    }
  }
}

.customize-dropdown-antd-menu-item-selected {
  background-color: initial !important;
  color: $active_menu !important;
}

.customize-dropdown-antd-menu-item {
  padding-left: 10px !important;
  padding-bottom: 0 !important;
}

@mixin overflowModal {
  overflow-y: auto;
  overflow-x: hidden;
}

@mixin modal-stock {
  height: 85vh;

  .header-content {
    height: 50px;
  }
  .main-content {
    height: calc(100% - 110px);
    @include overflowModal;
  }
  .main-content-mission {
    height: calc(100% - 85px);
    @include overflowModal;
  }
  .main-content-2 {
    height: calc(100% - 130px);
    @include overflowModal;
  }
  .footer-content {
    height: 60px;
  }
  .modal-new-stock-content {
    height: 100%;
    padding: 10px 24px;
  }

  .modal-new-stock-body {
    height: 100%;
  }

  .footer-content {
    border-top: 1px solid #b7b7b7;
    .btn-submit-modal {
      padding: 8px 20px;
      color: white;
      background-color: #01e37a;
      height: fit-content;
      border-radius: 7px;

      cursor: pointer;
      border: none;
    }

    .disable {
      background-color: #b7b7b7;
      cursor: not-allowed;
    }
  }

  .ant-spin-container {
    height: 100%;
  }

  .ant-spin-nested-loading {
    height: 100%;
  }
}

.modal-new-stock {
  @include modal-stock();
}

.modal-new-stock-view-only {
  @include modal-stock();
  .modal-new-stock-view-only-content {
    height: 100%;
    padding: 10px 24px;
  }

  .modal-new-stock-view-only-body {
    height: 100%;
  }
  .footer-content {
    height: 0;
  }
  .main-content {
    height: calc(100% - 60px);
    overflow: auto;
  }
}

.focused {
  border: 1px solid $secondary !important;
  border-radius: 8px !important;
}

.attendu-cmd-stock {
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
  }

  ::-webkit-scrollbar-track {
    margin-left: 190px;
    background-color: #dbdbdb;
    border: solid 20px transparent;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb {
    width: 10px !important;
    padding: 0 !important;
    margin: 0 !important;
  }
}

.table-customize-configuration {
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
  }

  ::-webkit-scrollbar-track {
    background-color: #dbdbdb;
    border: solid 20px transparent;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb {
    width: 10px !important;
    padding: 0 !important;
    margin: 0 !important;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $secondary;
    border: solid 20px transparent;
  }

  .ant-table-thead >tr>th {
    color: $gray;
    border-right: 1px solid $border_header_table;
    border-bottom: 1px solid $border_header_table;
    background-color: $background_header_table !important;

    &:last-child {
      border-left: 1px solid $border_header_table;
    }

    &:nth-last-child(-n + 2){
      border-right: 0;
      border-top-right-radius: 8px;
    }
  }

  .ant-table-tbody >tr >td {
    border-right: 1px solid #f0f0f0;
  }

  .ant-table-tbody >tr >td:last-child {
    border-left: 1px solid #f0f0f0;
  }

  .ant-table-cell-fix-left {
    background: #ffffff !important;
  }

  .table-row-main-odd {
    background-color: #f9f9f9 !important;

    .ant-table-cell-fix-left {
      background-color: #f9f9f9 !important;
    }

    .ant-table-cell-fix-right {
      background-color: #f9f9f9 !important;
    }
  }

  .ant-table-content {
    border-start-start-radius: 8px;
    border-start-end-radius: 8px;
  }

  .ant-table-cell-fix-right-first::after {
    box-shadow: unset !important;
  }

  .table-row-child {
    background-color: #fff !important;
  }

  .ant-table-row {
    &:hover {
      background-color: #f9f9f9 !important;

      .ant-table-cell-fix-left {
        background-color: #f9f9f9 !important;
        color: #808080 !important;
      }
    }
  }

  .ant-table-tbody > tr.ant-table-row:hover > td .anticon {
    color: #808080;
  }

  .cron-text {
    min-width: 70px;
    text-align: left;
  }

  .cron-icon {
    display: flex;
    justify-content: center;
    min-width: 70px;
  }

  .first-column {
    padding-left: 16px !important;

    .truncate {
      width: 167px;
    }
  }
}

.custom-control {
  .ant-input-affix-wrapper {
    padding: 0 11px !important;
  }

  .ant-select-selector {
    padding-top: 0 !important;
    height: 25px !important;
    display: flex !important;
    align-items: center !important;
    //min-width: 100px;

    &::after {
      margin: 0 !important;
    }
  }

  input {
    border: 1px solid #BEBEBE;
    height: 25px;
    gap: 0;
    border-radius: 7px;
  }

  .ant-select-selection-placeholder {
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -1.4px;
    color: #848484 !important;
  }

  .ant-select-selection-search-input {
    height: 23px !important;
  }

  .react-js-cron {
    justify-content: center;
  }

  .react-js-cron-field {
    margin-bottom: 0 !important;
  }
}