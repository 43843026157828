@import '../../../assets/scss/color';

.custom-dropdown .ant-select .ant-select-arrow {
  color: black;
  border-left: 1px solid $border_input;
  background-color: white;
  padding: 10px;
  position: absolute;
  right: 0;
  top: 6px;
  border-radius: 0 20% 20% 0;
  height: 100%;
}

.custom-dropdown {
  .ant-select-selection-placeholder {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  .default {
    color: #000000;
    border: 1px solid #bebebe;
  }
  .primary {
    color: #ffffff;
    border: 2px solid #0189e3;
  }
}

.custom-dropdown .ant-select {
  border-radius: 0.375rem;
  background-color: #ffffff;
}

.default.ant-select .ant-select-arrow {
  background-color: white;
  color: $border_input;
  font-size: 0.8rem;
  border-left: 1px solid $border_input;
}

.primary.ant-select .ant-select-arrow {
  color: white;
  background-color: $secondary;
  border-radius: 0 10% 10% 0;
  border-left: transparent;
}

.ant-select-selection-placeholder {
  color: $placeholder_input;
  font-weight: 500;
}

.research-bar-input::placeholder {
  font-family: Montserrat;
  font-size: 14px;
}

.action-dropdown .ant-select-selection-placeholder {
  margin-top: 4px;
}
